html, body { height: 100% }
#root {
    height: 100%;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100%;
}
